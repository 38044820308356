<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Reason To Block Student</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-data-table :headers="headers" :items="blockreasonlist" class="elevation-1" :search="search">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
          </v-text-field>
          <v-spacer></v-spacer>
          <span style="width: 20px"></span>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Add Reason To Block Student</v-btn>
            </template>
            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <!-- <span class="headline">{{ formTitle }}</span> -->
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="editedItem.block_reason" label="Enter Reason To Block Student"
                          :rules="[(v) => !!v || 'required']" persistent-hint>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                  <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                  <!-- :disabled="!valid" -->
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
          </v-avatar>
          <v-btn text>
            <v-icon color="red" @click="deletefun(item.id)">
              mdi-delete</v-icon></v-btn>
        </div>
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    overlay: false,
    valid: false,
    errorMsg: {
      id: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    blockreasonlist: [],
    block_reason: "",
    headers: [
      {
        text: "Sr.No",
        sortable: true,
        value: "sr_no"
      },
      {
        text: "Reason To Block Student",
        align: "left",
        sortable: true,
        value: "block_reason",
      },


      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    blockreason_master: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      block_reason: "",
    },
    defaultItem: {
      id: "",
      block_reason: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Reason To Block Student"
        : "Edit Reason";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },

  // created () {
  //   this.initialize()
  // },
  mounted() {
    this.onLoad();
  },
  methods: {
    hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },
    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
    },
    onLoad() {
      this.overlay = true;

      axios
        .post("/AdminMaster/addReasonToBlockStudent")
        .then((res) => {
          if (res.data.msg == "200") {
            if ((this.load = true)) {
              this.overlay = false;
              this.blockreasonlist = res.data.blockreasonlist;

            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
        })
        .finally(() => {
          // 'use strict';
          var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem(item) {
      this.editedIndex = this.blockreasonlist.indexOf(item);
      //console.log(this.editedIndex)
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedItem.block_reason == "" || /^[ \d]+$/.test(this.editedItem.block_reason) || this.hasWhiteSpace(this.editedItem.block_reason)) {
        this.showSnackbar("red", "Please Enter Reason To Block Student..."); // show snackbar on success
        return;
      }
      else {
        if (this.editedIndex > -1) {
          if (this.isValidated()) {
            axios
              .post("/AdminMaster/editReasonToBlockStudent", this.editedItem)
              .then((res) => {
                if (res.data.status.code == "SUCCESS") {
                  // this.blockreasonlist = res.data.blockreasonlist;
                  // Object.assign(
                  //   this.blockreasonlist[this.editedIndex],
                  //   this.editedItem
                  // );
                  this.onLoad();
                  this.showSnackbar("#4caf50", "Reason Updated Successfully..."); // show snackbar on success
                } else if (res.data.status.code == "NA") {
                  this.showSnackbar("#b71c1c", "Reason Already Present!!!"); // show snackbar on error
                }
              })
              .catch((error) => {
                window.console.log(error);
              });

            this.close();
          }
        } else {
          axios
            .post("/AdminMaster/saveReasonToBlockStudent", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {

                this.showSnackbar("#4caf50", "Reason Added Successfully..."); // show snackbar on success
                // this.blockreasonlist = res.data.blockreasonlist;
                // this.atype_list.push(this.editedItem);
                this.onLoad();
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "Reason Already Present!!!"); // show snackbar on error
              }
              this.onLoad();
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      }
      // console.log(this.editedIndex);

    }, // end of save()

    isValidated() {
      if (this.editedItem.id) {
        return true;
      } else {
        if (!this.editedItem.id) {
          this.errorMsg.id = true;
        }

        return false;
      }
    }, // .....end of isValidated()
    deletefun(item) {
      //console.log("a");
      const data = {
        deleteitem: item,
      };
      //  console.log("a1");
      axios
        .post("/AdminMaster/deleteReasonToBlockStudent", data)
        .then((res) => {
          // console.log("a2");
          if (res.data.msg == "200") {
            this.blockreasonlist = res.data.blockreasonlist;
            //    console.log("success");
            this.showSnackbar("#4caf50", "Deleted Successfully...");
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
